<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    class="modal--nego-history"
    :name="modalName"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('negoHistory.title') }}
      </div>
      <section-loader :show="loading" />
      <section class="modal--container">
        <div class="title--text" v-if="$mq !== 'xs'">{{ $t('negoHistory.title') }}</div>
        <div class="d-flex justify-content-between nego--history-header" v-if="listing">
          <div>{{ $t(`negoHistory.${listing.type}.applicant`) }}</div>
          <div>{{ $t(`negoHistory.${listing.type}.owner`) }}</div>
        </div>
        <chat-box :messages="messages" :noTypeBox="true"></chat-box>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';

const SectionLoader = () => import('@/components/content-loading/section-loading');
import ChatBox from '@/components/inbox/partials/chat-box';

export default {
  name: 'nego-history-modal',
  components: { SectionLoader, ChatBox },
  data() {
    return {
      modalName: 'nego-history-modal',
      listing: null,
      negoData: null,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.global.user,
    }),
    messages() {
      if (this.listing && this.negoData && this.negoData.nego_histories) {
        let messages = [],
          status = null,
          i = 0;
        for (let data of this.negoData.nego_histories) {
          let messageBody =
            this.$i18n.t(
              `negoHistory.${this.listing.type}.${data.is_applicant ? 'applicantAsk' : 'ownerAsk'}`,
            ) +
            ' ' +
            data.price;
          if (i === this.negoData.nego_histories.length - 1) {
            switch (this.negoData.status) {
              case 'COMPLETED': {
                messageBody = this.$i18n.t(
                  `negoHistory.${this.listing.type}.${
                    data.is_applicant ? 'applicantAccept' : 'ownerAccept'
                  }`,
                );
                status = this.negoData.status;
                break;
              }
              case 'CANCELED':
                {
                  messageBody = this.$i18n.t(
                    `negoHistory.${this.listing.type}.${
                      data.is_applicant ? 'applicantReject' : 'ownerReject'
                    }`,
                  );
                }
                status = this.negoData.status;
                break;
            }
          }
          messages.push({
            createdAt: data.created_at,
            messageBody: messageBody,
            senderId: !data.is_applicant ? this.currentUser.id : null,
            status: status,
          });
          i++;
        }
        return messages;
      } else {
        return [];
      }
    },
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    beforeOpen(event) {
      if (event.params.negoData) {
        this.negoData = event.params.negoData;
        this.listing = event.params.listing;
      }
    },
  },
};
</script>

<style scoped></style>

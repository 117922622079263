<template>
  <section class="booking--detail-transaction">
    <div class="title--text">
      {{ $t('booking.detail.transaction') }}
    </div>
    <div class="transaction--container" v-if="bookingInfo">
      <div class="single--row" v-if="bookingInfo.order_number">
        <div class="left--col">{{ $t('booking.detail.order') }}</div>
        <div class="right--col" v-if="!bookingInfo.free_reservation_fee">
          <a target="_blank" :href="`/invoice?order_number=${bookingInfo.order_number}`">{{
            bookingInfo.order_number
          }}</a>
        </div>
        <div class="right--col" v-else>
          {{ bookingInfo.order_number }}
        </div>
      </div>

      <div
        class="single--row"
        v-if="bookingInfo.listing_type === 'S' && !bookingInfo.done_year_est"
      >
        <div class="left--col">Status</div>
        <div class="right--col">Ready for Occupancy</div>
      </div>
      <div class="single--row" v-if="bookingInfo.listing_type === 'R'">
        <div class="left--col">{{ $t('general.startFrom') }}</div>
        <div class="right--col">{{ dateFormatStart }}</div>
      </div>
      <div class="single--row" v-if="bookingInfo.listing_type === 'R'">
        <div class="left--col">{{ $t('general.until') }}</div>
        <div class="right--col">{{ dateFormatEnd }}</div>
      </div>
      <div class="single--row" v-if="bookingInfo.listing_type === 'R'">
        <div class="left--col">{{ $t('general.rentDuration') }}</div>
        <div class="right--col">{{ bookingInfo.rent_period }}</div>
      </div>
      <div class="single--row" v-if="bookingInfo.listing_type === 'S'">
        <div class="left--col">{{ $t('general.price') }}</div>
        <div class="right--col">
          {{
            $n(
              getPriceAfterDiscount(
                bookingInfo.discount_percentage,
                bookingInfo.discount_price,
                bookingInfo.sell_price_number,
              ).price_after_discount,
              'currency',
              'en-SG',
            )
          }}
        </div>
      </div>
      <!--      <div class="single&#45;&#45;row" v-if="bookingInfo.listing_type === 'S' && bookingInfo.done_year_est">-->
      <!--        <div class="left&#45;&#45;col">Estimasi Tahun Jadi</div>-->
      <!--        <div class="right&#45;&#45;col">{{ bookingInfo.done_year_est }}</div>-->
      <!--      </div>-->
      <div v-if="bookingInfo.promo_code">
        <div class="single--row">
          <div class="left--col">{{ $t('general.subPrice') }}</div>
          <div class="right--col">{{ bookingInfo.sub_total }}</div>
        </div>
        <div class="single--row">
          <div class="left--col">Promo {{ bookingInfo.promo_code }}</div>
          <div class="right--col discount--text">- {{ bookingInfo.discount }}</div>
        </div>
      </div>
      <div class="single--row" v-if="bookingInfo.listing_type === 'R'">
        <div class="left--col">{{ $t('general.totalPrice') }}</div>
        <div class="right--col">{{ bookingInfo.total_rent_price }}</div>
      </div>
      <div class="single--row final--row" v-if="bookingInfo.listing_type === 'R'">
        <div class="left--col">{{ $t('general.bookingFee') }}</div>
        <div class="right--col">{{ bookingInfo.booking_fee }}</div>
      </div>
      <div
        class="single--row final--row"
        v-if="bookingInfo.listing_type === 'S' && bookingInfo.otp_fee"
      >
        <!--        <div class="left&#45;&#45;col">{{ $t('booking.detail.reservationFee') }}</div>-->
        <div class="left--col">{{ $t('general.bookingFee') }}</div>
        <div class="right--col">{{ bookingInfo.otp_fee }}</div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'booking-info',
  mixins: [HelperMixin],
  computed: {
    ...mapState({
      bookingInfo: state => state.v2.booking.detail.bookingInfo,
    }),
    dateFormatStart() {
      if (this.bookingInfo) {
        let format = 'dd MMMM yyyy';
        if (this.bookingInfo.rent_period_id === 5) format = 'dd MMMM yyyy HH:mm';
        return this.$date.format(this.bookingInfo.start_date, format);
      }
      return '';
    },
    dateFormatEnd() {
      if (this.bookingInfo) {
        let format = 'dd MMMM yyyy';
        if (this.bookingInfo.rent_period_id === 5) format = 'dd MMMM yyyy HH:mm';
        return this.$date.format(this.bookingInfo.end_date, format);
      }
      return '';
    },
  },
};
</script>

<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('booking.modal.signAgreement.title') }}
      </div>
      <SectionLoader :show="loading" />
      <section class="modal--container accept--survey-container" v-if="!digitalSignPreview">
        <div class="title--text" v-if="$mq !== 'xs'">
          {{ $t('booking.modal.signAgreement.title') }}
        </div>
        <div class="date--time">
          {{ $t('booking.modal.signAgreement.message') }}
        </div>
        <div class="d-flex justify-content-center" v-if="modalData">
          <div class="btn btn-primary--outline main--btn" @click="previewAgreement">
            <img src="@/assets/img/icons/document.svg" class="mr-2" />
            <span>{{ $t('general.btn.previewAgreement') }}</span>
          </div>
        </div>
        <hr class="separator" />
        <div class="d-flex justify-content-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-danger main--btn" @click="reject">
            {{ $t('general.btn.rejectAgreement') }}
          </div>
          <div class="btn btn-primary main--btn" @click="sign">
            {{ buttonName }}
          </div>
          <!--          <div class="btn btn-primary main&#45;&#45;btn" @click="close">-->
          <!--            Terima Permintaan Survei-->
          <!--          </div>-->
        </div>
      </section>
      <section class="modal--container accept--survey-container" v-else>
        <div class="d-flex justify-content-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-primary--outline main--btn mt-0 mb-4" @click="back">
            {{ $t('general.btn.back') }}
          </div>
        </div>
        <div class="title--text">{{ $t('booking.digitalAgreementPreview') }}</div>
        <hr />
        <div v-html="digitalSignPreview" class="preview--wrapper"></div>
        <hr class="separator" />
        <div class="d-flex justify-content-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-primary--outline main--btn" @click="back">
            {{ $t('general.btn.back') }}
          </div>
        </div>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import ModalMixin from '../mixins/index';
import HelperMixin from '@/mixins/helpers';
const SectionLoader = () => import('@/components/content-loading/section-loading');

export default {
  name: 'sign-agreement-modal',
  mixins: [ModalMixin, HelperMixin],
  components: { SectionLoader },
  data() {
    return {
      modalName: 'sign-agreement-modal',
      loading: false,
      buttonName: '',
      modalData: null,
      actionType: 'RENT_TRANSACTION',
      allowedActions: [],
      digitalSignPreview: '',
    };
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    async beforeOpen(event) {
      this._applicationUuid = event.params.uuid;
      this.buttonName = event.params.buttonName;
      this.allowedActions = event.params.allowedActions;
      await this._fetchApplicationData();
    },
    async previewAgreement() {
      if (this.modalData.agreement_url) {
        window.open(this.modalData.agreement_url, '_blank');
      } else {
        let formData = {
          rent_transaction_uuid: this._applicationUuid,
        };
        const result = await this.$store.dispatch(
          'v2/booking/acceptBookingRequest/previewDigitalAgreement',
          formData,
        );
        if (result && result.template) {
          this.digitalSignPreview = result.template;
        }
      }
    },
    back() {
      this.digitalSignPreview = null;
    },
    async reject() {
      this.loading = true;
      try {
        let actionNames = this.allowedActions.map(elem => {
          return elem.name.toUpperCase();
        });
        if (actionNames.indexOf('CANCEL_APPLICANT_SIGN') !== -1) {
          await this.$store.dispatch(
            'v2/rentTransaction/cancelApplicantSign',
            this._applicationUuid,
          );
        } else if (actionNames.indexOf('CANCEL_OWNER_ACCEPT') !== -1) {
          await this.$store.dispatch(
            'v2/rentTransaction/cancelOwnerConfirmBooking',
            this._applicationUuid,
          );
        }
        this.$emit('actionDone');
        this.close();
        this.$swal(
          this.$t('general.success'),
          this.$t('booking.swal.rejectAgreement.message'),
          'success',
        );
        // eslint-disable-next-line no-empty
      } catch (_) {
      } finally {
        this.loading = false;
      }
    },
    async sign() {
      this.loading = true;
      try {
        await this.$store.dispatch('v2/rentTransaction/signAgreement', this._applicationUuid);
        this.$emit('actionDone');
        this.close();
        this.$swal(
          this.$t('general.success'),
          this.$t('booking.swal.signAgreement.message'),
          'success',
        );
        // eslint-disable-next-line no-empty
      } catch (_) {
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>

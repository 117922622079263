<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('booking.modal.negotiation.title') }}
      </div>
      <SectionLoader :show="loading" />
      <section class="modal--container accept--survey-container">
        <div class="title--text" v-if="$mq !== 'xs'">
          {{ $t('booking.modal.negotiation.title') }}
        </div>
        <div class="date--time">
          {{ $t('booking.modal.negotiation.message') }}
          <div class="mt-4" v-if="modalData">
            <div class="single--row" v-if="startDate">
              <div class="left--col">{{ $t('general.startFrom') }}</div>
              <div class="right--col">{{ startDate }}</div>
            </div>
            <div class="single--row" v-if="endDate">
              <div class="left--col">{{ $t('general.until') }}</div>
              <div class="right--col">{{ endDate }}</div>
            </div>
            <div class="single--row" v-if="duration">
              <div class="left--col">{{ $t('general.rentDuration') }}</div>
              <div class="right--col">{{ modalData.duration }}</div>
            </div>
            <div class="single--row" v-if="modalData.final_price">
              <div class="left--col">{{ $t('general.initialPrice') }}</div>
              <div class="right--col">{{ modalData.final_price }}</div>
            </div>
            <div class="single--row">
              <div class="left--col">{{ $t('general.negoPrice') }}</div>
              <div class="right--col">{{ modalData.nego_price_text }}</div>
            </div>
            <!--            <div class="single&#45;&#45;row">-->
            <!--              <div class="left&#45;&#45;col">-->
            <!--                {{ `${$t('general.pricePerShort')} ${$t('date.month.title')}` }}-->
            <!--              </div>-->
            <!--              <div class="right&#45;&#45;col">{{ $n(modalData.nego_price, 'currency', 'en-SG') }}</div>-->
            <!--            </div>-->
          </div>
        </div>
        <div
          v-if="modalData"
          class="form--group"
          :class="{ 'has-error': validation.hasError('newPrice') }"
        >
          <div class="left--col">
            <span>{{
              actionType === 'RENT_TRANSACTION'
                ? `${$t('general.finalPricePer')} ${$t('date.month.title')}`
                : `${$t('general.finalPrice')}`
            }}</span>
          </div>
          <div class="right--col">
            <div class="input--group">
              <div class="append--left">
                S$
              </div>
              <cleave
                class="basic--input price--input"
                type="text"
                name="new_price"
                v-model="newPrice"
                :options="cleaveOption"
                ref="price"
              />
            </div>
            <span class="val-error" v-if="validation.hasError('newPrice')">{{
              validation.firstError('newPrice')
            }}</span>
          </div>
        </div>
        <div v-if="modalData && actionType === 'RENT_TRANSACTION'" class="form--group mt-2">
          <div class="left--col">
            <span>{{ `${$t('general.newTotalPrice')}` }}</span>
          </div>
          <div class="right--col">
            <div class="input--group">
              <div class="append--left">
                S$
              </div>
              <cleave
                class="basic--input price--input"
                type="text"
                name="new_price"
                :value="newTotalPrice"
                :options="cleaveOption"
                ref="price"
                readonly
              />
            </div>
          </div>
        </div>
        <button
          class="btn btn-primary mt-4 mx-auto"
          style="width: fit-content;"
          @click="renegotiate"
          v-if="modalData"
          :disabled="newPrice === modalData.nego_price.toString()"
        >
          {{ $t('booking.modal.negotiation.renegotiate') }}
        </button>
        <hr class="separator mt-4" />
        <div class="d-flex justify-content-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-danger main--btn mt-4" @click="cancel">
            {{ $t('booking.modal.negotiation.cancel') }}
          </div>
          <div class="btn btn-primary main--btn mt-4" @click="accept">
            {{ $t('booking.modal.negotiation.accept') }}
          </div>
          <!--          <div class="btn btn-primary main&#45;&#45;btn" @click="close">-->
          <!--            Terima Permintaan Survei-->
          <!--          </div>-->
        </div>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import ModalMixin from '../mixins/index';
import HelperMixin from '@/mixins/helpers';
import { Validator } from 'simple-vue-validator';
import Cleave from 'vue-cleave-component';
const SectionLoader = () => import('@/components/content-loading/section-loading');

export default {
  name: 'nego-modal',
  mixins: [ModalMixin, HelperMixin],
  components: { Cleave, SectionLoader },
  data() {
    return {
      cleaveOption: {
        prefix: '',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralIntegerScale: 16,
        numeralDecimalScale: 0,
      },
      modalName: 'nego-modal',
      modalData: null,
      actionType: null,
      startDate: '',
      endDate: '',
    };
  },
  computed: {
    loading: {
      get() {
        return this.$store.state.nego.loading;
      },
      set(val) {
        this.$store.commit('nego/SET_LOADING', val);
      },
    },
    uuid: {
      get() {
        return this.$store.state.nego.uuid;
      },
      set(val) {
        this.$store.commit('nego/SET_UUID', val);
      },
    },
    newPrice: {
      get() {
        return this.$store.state.nego.newPrice;
      },
      set(val) {
        this.$store.commit('nego/SET_NEW_PRICE', val);
      },
    },
    pricePerDuration() {
      if (this.modalData.nego_price && this.modalData.duration) {
        let result = parseFloat(this.modalData.nego_price) / parseFloat(this.modalData.duration);
        return this.$n(result, 'currency', 'en-SG').replace('$', '$ ');
      } else {
        return 0;
      }
    },
    newTotalPrice() {
      if (this.newPrice) {
        return parseFloat(this.newPrice) * parseFloat(this.modalData.duration);
      } else {
        return 0;
      }
    },
  },
  validators: {
    newPrice(value) {
      return Validator.value(value).required(this.$i18n.t('errors.newPrice.required'));
    },
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    async beforeOpen(event) {
      this._applicationUuid = event.params.uuid;
      this.actionType = event.params.actionType;
      await this._fetchApplicationData();
      if (this.modalData) {
        this.uuid = this.modalData.nego_uuid;
        this.newPrice = this.modalData.nego_price;
        if (this.modalData.move_date) {
          let splitMoveDate = this.modalData.move_date.split('-');
          if (splitMoveDate && splitMoveDate.length === 2) {
            this.startDate = splitMoveDate[0].trim();
            this.endDate = splitMoveDate[1].trim();
          } else {
            this.startDate = this.modalData.move_date;
          }
        }
      }
    },
    async cancel() {
      try {
        await this.$store.dispatch('nego/cancel');
        this.$emit('actionDone');
        this.close();
        this.$swal(
          this.$t('general.success'),
          this.$t('booking.swal.cancelNego.message'),
          'success',
        );
        // eslint-disable-next-line no-empty
      } catch (_) {}
    },
    async accept() {
      try {
        await this.$store.dispatch('nego/accept');
        this.$emit('actionDone');
        this.close();
        this.$swal(
          this.$t('general.success'),
          this.$t('booking.swal.acceptNego.message'),
          'success',
        );
        // eslint-disable-next-line no-empty
      } catch (_) {}
    },
    async renegotiate() {
      try {
        if (this.$validate()) {
          await this.$store.dispatch('nego/renegotiate');
          this.$emit('actionDone');
          this.close();
          this.$swal(
            this.$t('general.success'),
            this.$t('booking.swal.renegotiateNego.message'),
            'success',
          );
        }
        // eslint-disable-next-line no-empty
      } catch (_) {}
    },
  },
};
</script>

<style scoped></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.timeline)?_c('section',{staticClass:"booking--timeline"},[(_vm.$mq === 'xs')?_c('div',{staticClass:"back--wrapper"},[_c('div',{staticClass:"back--button",on:{"click":function($event){return _vm.close()}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/back-2.svg")}})]),_vm._v(" Detail Status ")]):_vm._e(),_vm._l((_vm.timeline),function(item,index){return _c('div',{key:index,staticClass:"wrapper",class:{
      cancelled: item.timestamp && item.type === 'CANCELED',
      'on-going': item.timestamp && item.type === 'ON_GOING',
      completed: item.timestamp && item.type === 'COMPLETED',
    }},[_c('div',{staticClass:"indicator",class:{
        'cancelled--indicator':
          index + 1 < _vm.timeline.length &&
          _vm.timeline[index + 1].timestamp &&
          _vm.timeline[index + 1].type === 'CANCELED',
        'on-going--indicator':
          index + 1 < _vm.timeline.length &&
          _vm.timeline[index + 1].timestamp &&
          _vm.timeline[index + 1].type === 'ON_GOING',
        'completed--indicator':
          index + 1 < _vm.timeline.length &&
          _vm.timeline[index + 1].timestamp &&
          _vm.timeline[index + 1].type === 'COMPLETED',
      }},[_c('div',{staticClass:"circle"},[_vm._v(_vm._s(index + 1))])]),_c('div',{staticClass:"content px-5"},[_c('div',{staticClass:"title--text"},[_vm._v(_vm._s(item.status_name))]),_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(item.status_message))]),(item.timestamp)?_c('div',{staticClass:"timestamp"},[_vm._v(" "+_vm._s(_vm.$date.format(item.timestamp, 'dd MMMM yyyy HH:mm'))+" ")]):_vm._e()])])})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    @before-open="beforeOpen"
    ref="modal"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{
          $t(
            !digitalSignPreview
              ? 'booking.modal.digitalAgreement.sellTitle'
              : 'booking.digitalAgreementPreview',
          )
        }}
      </div>
      <SectionLoader :show="isLoading" />
      <section class="modal--container accept--survey-container" v-if="!digitalSignPreview">
        <div class="title--text">
          {{ $t('booking.modal.digitalAgreement.sellTitle') }}
        </div>
        <div class="form--group mt-2" :class="{ 'has-error': validation.hasError('ownerName') }">
          <div class="left--col">
            <label for="ownerName">
              <span>{{ $t('booking.modal.digitalAgreement.ownerName') }}</span>
              <span class="required">*</span>
            </label>
          </div>
          <div class="right--col">
            <input id="ownerName" v-model="ownerName" class="basic--input" />
            <span class="val-error mt-2" v-if="validation.hasError('ownerName')">{{
              validation.firstError('ownerName')
            }}</span>
          </div>
        </div>
        <div class="form--group mt-2" :class="{ 'has-error': validation.hasError('ownerKtp') }">
          <div class="left--col">
            <label for="ownerKtp">
              <span>{{ $t('booking.modal.digitalAgreement.ownerKtp') }}</span>
              <span class="required">*</span>
            </label>
          </div>
          <div class="right--col">
            <input id="ownerKtp" @keypress="isNumber" v-model="ownerKtp" class="basic--input" />
            <span class="val-error mt-2" v-if="validation.hasError('ownerKtp')"
              >{{ validation.firstError('ownerKtp') }}
            </span>
          </div>
        </div>
        <div class="date--time mt-2 p-3">
          {{ $t('booking.modal.digitalAgreement.bankDesc') }}
        </div>
        <div
          class="form--group mt-2"
          :class="{ 'has-error': validation.hasError('selectedBankType') }"
        >
          <div class="left--col">
            <label for="bankFormBankList">
              <span>{{ $t('bankForm.bankAccountType') }}</span>
              <span class="required">*</span>
            </label>
          </div>
          <div class="right--col">
            <multiselect
              name="searchKeyword"
              id="bankFormBankList"
              v-model="selectedBankType"
              :optionsLimit="7"
              :options="banks"
              :searchable="true"
              :close-on-select="true"
              track-by="id"
              label="name"
            >
              <template slot="caret">
                <div class="multiselect__select select--search">
                  <i class="ion-ios-search"></i>
                </div>
              </template>
            </multiselect>
            <span class="val-error mt-2" v-if="validation.hasError('selectedBankType')"
              >{{ validation.firstError('selectedBankType') }}
            </span>
          </div>
        </div>
        <div
          class="form--group mt-2"
          :class="{ 'has-error': validation.hasError('lawPracticeName') }"
          v-if="selectedBankType && selectedBankType.id === 2"
        >
          <div class="left--col">
            <label for="lawPracticeName">
              <span>{{ $t('bankForm.lawPracticeName') }}</span>
              <span class="required">*</span>
            </label>
          </div>
          <div class="right--col">
            <input id="lawPracticeName" v-model="lawPracticeName" class="basic--input" />
            <span class="val-error mt-2" v-if="validation.hasError('lawPracticeName')"
              >{{ validation.firstError('lawPracticeName') }}
            </span>
          </div>
        </div>
        <div
          class="form--group mt-2"
          :class="{ 'has-error': validation.hasError('bankName') }"
          v-if="selectedBankType && selectedBankType.id === 2"
        >
          <div class="left--col">
            <label for="bankName">
              <span>{{ $t('bankForm.bankName') }}</span>
              <span class="required">*</span>
            </label>
          </div>
          <div class="right--col">
            <input id="bankName" v-model="bankName" class="basic--input" />
            <span class="val-error mt-2" v-if="validation.hasError('bankName')"
              >{{ validation.firstError('bankName') }}
            </span>
          </div>
        </div>
        <div
          class="form--group mt-2"
          :class="{ 'has-error': validation.hasError('bankAccountNumber') }"
          v-if="selectedBankType && selectedBankType.id === 2"
        >
          <div class="left--col">
            <label for="bankAccountNumber">
              <span>{{ $t('bankForm.accountNumber') }}</span>
              <span class="required">*</span>
            </label>
          </div>
          <div class="right--col">
            <input
              id="bankAccountNumber"
              @keypress="isNumber"
              v-model="bankAccountNumber"
              class="basic--input"
            />
            <span class="val-error mt-2" v-if="validation.hasError('bankAccountNumber')"
              >{{ validation.firstError('bankAccountNumber') }}
            </span>
          </div>
        </div>
        <div
          class="form--group mt-2"
          :class="{ 'has-error': validation.hasError('solicitorName') }"
          v-if="selectedBankType && selectedBankType.id === 3"
        >
          <div class="left--col">
            <label for="solicitorName">
              <span>{{ $t('bankForm.solicitorName') }}</span>
              <span class="required">*</span>
            </label>
          </div>
          <div class="right--col">
            <input id="solicitorName" v-model="solicitorName" class="basic--input" />
            <span class="val-error mt-2" v-if="validation.hasError('solicitorName')"
              >{{ validation.firstError('solicitorName') }}
            </span>
          </div>
        </div>
        <div
          class="form--group mt-2"
          :class="{ 'has-error': validation.hasError('lawFirmName') }"
          v-if="selectedBankType && selectedBankType.id === 3"
        >
          <div class="left--col">
            <label for="lawFirmName">
              <span>{{ $t('bankForm.lawFirmName') }}</span>
              <span class="required">*</span>
            </label>
          </div>
          <div class="right--col">
            <input id="lawFirmName" v-model="lawFirmName" class="basic--input" />
            <span class="val-error mt-2" v-if="validation.hasError('lawFirmName')"
              >{{ validation.firstError('lawFirmName') }}
            </span>
          </div>
        </div>
        <div class="form--group mt-2" :class="{ 'has-error': validation.hasError('agentName') }">
          <div class="left--col">
            <label for="agentName">
              <span>{{ $t('booking.modal.digitalAgreement.agentName') }}</span>
              <!--              <span class="required">*</span>-->
            </label>
          </div>
          <div class="right--col">
            <input id="agentName" v-model="agentName" class="basic--input" />
            <span class="val-error mt-2" v-if="validation.hasError('agentName')"
              >{{ validation.firstError('agentName') }}
            </span>
          </div>
        </div>
        <div
          class="form--group mt-2"
          :class="{ 'has-error': validation.hasError('agentCeaNumber') }"
        >
          <div class="left--col">
            <label for="agentCeaNumber">
              <span>{{ $t('booking.modal.digitalAgreement.agentCeaNumber') }}</span>
              <!--              <span class="required">*</span>-->
            </label>
          </div>
          <div class="right--col">
            <input
              id="agentCeaNumber"
              @keypress="isNumber"
              v-model="agentCeaNumber"
              class="basic--input"
            />
            <span class="val-error mt-2" v-if="validation.hasError('agentCeaNumber')"
              >{{ validation.firstError('agentCeaNumber') }}
            </span>
          </div>
        </div>
        <div class="disclaimer mt-2">
          {{ $t('booking.modal.digitalAgreement.disclaimer') }}
        </div>

        <div class="d-flex justify-content-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-primary--outline main--btn" @click="back">
            {{ $t('general.btn.back') }}
          </div>
          <div v-if="modalData" class="btn btn-primary main--btn" @click="submit">
            {{ $t('booking.modal.acceptSellBooking.previewOtp') }}
          </div>
        </div>
      </section>
      <section class="modal--container accept--survey-container" v-else>
        <div class="d-flex justify-content-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-primary--outline main--btn mt-0 mb-4" @click="back">
            {{ $t('general.btn.back') }}
          </div>
        </div>
        <div class="title--text">{{ $t('booking.digitalAgreementPreview') }}</div>
        <hr />
        <div v-html="digitalSignPreview" class="preview--wrapper"></div>
        <hr class="separator" />
        <div class="d-flex justify-content-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-primary--outline main--btn" @click="back">
            {{ $t('general.btn.back') }}
          </div>
        </div>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import ModalMixin from '../mixins/index';
import HelperMixin from '@/mixins/helpers';
import { Validator } from 'simple-vue-validator';
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
import { mapGetters, mapState } from 'vuex';
const SectionLoader = () => import('@/components/content-loading/section-loading');

export default {
  name: 'sell-digital-agreement-modal',
  mixins: [ModalMixin, HelperMixin],
  components: {
    SectionLoader,
    Multiselect,
  },
  data() {
    return {
      modalName: 'sell-digital-agreement-modal',
      modalData: null,
      startDate: '',
      endDate: '',
      actionType: null,
      digitalSignPreview: '',
      banks: [
        {
          id: 1,
          name: "Singapore's Academy of Law Bank Account",
        },
        {
          id: 2,
          name: "Law Practice's Bank Account",
        },
        {
          id: 3,
          name: "Vendor Solicitor's Bank Account",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      formData: 'sellTransaction/acceptRequest/formData',
    }),
    ...mapState({}),
    isLoading: {
      get() {
        return this.$store.state.sellTransaction.acceptRequest.isLoading;
      },
      set(val) {
        this.$store.commit('sellTransaction/acceptRequest/SET_IS_LOADING', val);
      },
    },
    ownerName: {
      get() {
        return this.$store.state.sellTransaction.acceptRequest.ownerName;
      },
      set(val) {
        this.$store.commit('sellTransaction/acceptRequest/SET_OWNER_NAME', val);
      },
    },
    ownerKtp: {
      get() {
        return this.$store.state.sellTransaction.acceptRequest.ownerKtp;
      },
      set(val) {
        this.$store.commit('sellTransaction/acceptRequest/SET_OWNER_KTP', val);
      },
    },
    selectedBankType: {
      get() {
        return this.$store.state.sellTransaction.acceptRequest.selectedBankType;
      },
      set(val) {
        this.$store.commit('sellTransaction/acceptRequest/SET_SELECTED_BANK_TYPE', val);
        if (val) {
          switch (val.id) {
            case 1: {
              this.lawPracticeName = null;
              this.bankName = null;
              this.bankAccountNumber = null;
              this.solicitorName = null;
              this.lawFirmName = null;
              break;
            }
            case 2: {
              this.solicitorName = null;
              this.lawFirmName = null;
              break;
            }
            case 3: {
              this.lawPracticeName = null;
              this.bankName = null;
              this.bankAccountNumber = null;
              break;
            }
          }
        }
      },
    },
    lawPracticeName: {
      get() {
        return this.$store.state.sellTransaction.acceptRequest.lawPracticeName;
      },
      set(val) {
        this.$store.commit('sellTransaction/acceptRequest/SET_LAW_PRACTICE_NAME', val);
      },
    },
    bankName: {
      get() {
        return this.$store.state.sellTransaction.acceptRequest.bankName;
      },
      set(val) {
        this.$store.commit('sellTransaction/acceptRequest/SET_BANK_NAME', val);
      },
    },
    bankAccountNumber: {
      get() {
        return this.$store.state.sellTransaction.acceptRequest.bankAccountNumber;
      },
      set(val) {
        this.$store.commit('sellTransaction/acceptRequest/SET_BANK_ACCOUNT_NUMBER', val);
      },
    },
    solicitorName: {
      get() {
        return this.$store.state.sellTransaction.acceptRequest.solicitorName;
      },
      set(val) {
        this.$store.commit('sellTransaction/acceptRequest/SET_SOLICITOR_NAME', val);
      },
    },
    lawFirmName: {
      get() {
        return this.$store.state.sellTransaction.acceptRequest.lawFirmName;
      },
      set(val) {
        this.$store.commit('sellTransaction/acceptRequest/SET_LAW_FIRM_NAME', val);
      },
    },
    agentName: {
      get() {
        return this.$store.state.sellTransaction.acceptRequest.agentName;
      },
      set(val) {
        this.$store.commit('sellTransaction/acceptRequest/SET_AGENT_NAME', val);
      },
    },
    agentCeaNumber: {
      get() {
        return this.$store.state.sellTransaction.acceptRequest.agentCeaNumber;
      },
      set(val) {
        this.$store.commit('sellTransaction/acceptRequest/SET_AGENT_CEA_NUMBER', val);
      },
    },
  },
  validators: {
    ownerName(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.name.required'))
        .maxLength(50, this.$i18n.t('errors.name.max', { maxChar: 50 }));
    },
    ownerKtp(value) {
      return Validator.value(value).required(this.$i18n.t('errors.identity_card_number.required'));
      // .length(16, this.$i18n.t('errors.identity_card_number.length', { char: 16 }));
    },
    selectedBankType(value) {
      return Validator.value(value).required(this.$i18n.t('errors.bankAccountType.required'));
    },
    lawPracticeName(value) {
      if (this.selectedBankType && this.selectedBankType.id === 2) {
        return Validator.value(value).required(this.$i18n.t('errors.lawPracticeName.required'));
      } else {
        return Validator.value(true);
      }
    },
    bankName(value) {
      if (this.selectedBankType && this.selectedBankType.id === 2) {
        return Validator.value(value).required(this.$i18n.t('errors.bankName.required'));
      } else {
        return Validator.value(true);
      }
    },
    bankAccountNumber(value) {
      if (this.selectedBankType && this.selectedBankType.id === 2) {
        return Validator.value(value).required(this.$i18n.t('errors.accountNumber.required'));
      } else {
        return Validator.value(true);
      }
    },
    solicitorName(value) {
      if (this.selectedBankType && this.selectedBankType.id === 3) {
        return Validator.value(value).required(this.$i18n.t('errors.solicitorName.required'));
      } else {
        return Validator.value(true);
      }
    },
    lawFirmName(value) {
      if (this.selectedBankType && this.selectedBankType.id === 3) {
        return Validator.value(value).required(this.$i18n.t('errors.lawFirmName.required'));
      } else {
        return Validator.value(true);
      }
    },
    agentName(value) {
      if (this.agentCeaNumber) {
        return Validator.value(value).required(this.$i18n.t('errors.agentName.required'));
      } else {
        return Validator.value(true);
      }
    },
    agentCeaNumber(value) {
      if (this.agentName) {
        return Validator.value(value).required(this.$i18n.t('errors.agentCeaNumber.required'));
      } else {
        return Validator.value(true);
      }
    },
  },
  methods: {
    async beforeOpen(event) {
      this._closed();
      this._applicationUuid = event.params.uuid;
      this.$store.commit('sellTransaction/acceptRequest/SET_UUID', this._applicationUuid);
      this.actionType = event.params.actionType;
      await this._fetchApplicationData();
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validate() {
      let validationArray = [];
      validationArray.push(this.$validate());
      return Promise.all(validationArray).then(function(results) {
        return (
          results.filter(function(result) {
            return !result;
          }).length === 0
        );
      });
    },
    back() {
      if (!this.digitalSignPreview) {
        this.$modal.show('sell-applicant-info', {
          uuid: this._applicationUuid,
          beforeAccepted: true,
          actionType: this.actionType,
        });
        this.close();
      } else {
        this.digitalSignPreview = null;
      }
    },
    async submit() {
      if (await this.validate()) {
        // try {
        // this.isLoading = true;
        // const result = await this.$store.dispatch(
        //   'sellTransaction/acceptRequest/postAcceptBooking',
        // );
        // if (result) {
        //   this.$emit('actionDone');
        //   this._close();
        //   await this.$swal(
        //     this.$t('general.success'),
        //     this.$t('booking.swal.bookingModal.acceptedMsg'),
        //     'success',
        //   );
        this.$router.push({
          path: `/booking/sign-otp/${this._applicationUuid}`,
          query: Object.assign(
            {
              action_name: 'OWNER_SIGN_OTP',
            },
            this.formData,
          ),
        });
        // }
        // eslint-disable-next-line no-empty
        // } catch (e) {
        // } finally {
        //   this.isLoading = false;
        // }
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>

<style scoped></style>

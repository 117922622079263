<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    @before-open="beforeOpen"
    ref="modal"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{
          $t(
            !digitalSignPreview
              ? 'booking.modal.digitalAgreement.title'
              : 'booking.digitalAgreementPreview',
          )
        }}
      </div>
      <SectionLoader :show="isLoading" />
      <section class="modal--container accept--survey-container" v-if="!digitalSignPreview">
        <div class="title--text">
          {{ $t('booking.modal.digitalAgreement.title') }}
        </div>
        <div class="form--group mt-2" :class="{ 'has-error': validation.hasError('ownerName') }">
          <div class="left--col">
            <label for="ownerName">
              <span>{{ $t('booking.modal.digitalAgreement.ownerName') }}</span>
              <span class="required">*</span>
            </label>
          </div>
          <div class="right--col">
            <input id="ownerName" v-model="ownerName" class="basic--input" />
            <span class="val-error mt-2" v-if="validation.hasError('ownerName')">{{
              validation.firstError('ownerName')
            }}</span>
          </div>
        </div>
        <div class="form--group mt-2" :class="{ 'has-error': validation.hasError('ownerKtp') }">
          <div class="left--col">
            <label for="ownerKtp">
              <span>{{ $t('booking.modal.digitalAgreement.ownerKtp') }}</span>
              <span class="required">*</span>
            </label>
          </div>
          <div class="right--col">
            <input id="ownerKtp" @keypress="isNumber" v-model="ownerKtp" class="basic--input" />
            <span class="val-error mt-2" v-if="validation.hasError('ownerKtp')"
              >{{ validation.firstError('ownerKtp') }}
            </span>
          </div>
        </div>
        <div class="form--group mt-2" :class="{ 'has-error': validation.hasError('ownerEmail') }">
          <div class="left--col">
            <label for="ownerEmail">
              <span>{{ $t('booking.modal.digitalAgreement.ownerEmail') }}</span>
              <span class="required">*</span>
            </label>
          </div>
          <div class="right--col">
            <input id="ownerEmail" type="email" v-model="ownerEmail" class="basic--input" />
            <span class="val-error mt-2" v-if="validation.hasError('ownerEmail')"
              >{{ validation.firstError('ownerEmail') }}
            </span>
          </div>
        </div>
        <!--        <div class="form&#45;&#45;group mt-2" :class="{ 'has-error': validation.hasError('selectedBank') }">-->
        <!--          <div class="left&#45;&#45;col">-->
        <!--            <label for="bankFormBankList">-->
        <!--              <span>{{ $t('bankForm.bankName') }}</span>-->
        <!--              <span class="required">*</span>-->
        <!--            </label>-->
        <!--          </div>-->
        <!--          <div class="right&#45;&#45;col">-->
        <!--            <multiselect-->
        <!--              name="searchKeyword"-->
        <!--              id="bankFormBankList"-->
        <!--              v-model="selectedBank"-->
        <!--              :optionsLimit="7"-->
        <!--              :options="bankList"-->
        <!--              :searchable="true"-->
        <!--              :close-on-select="true"-->
        <!--              track-by="id"-->
        <!--              label="name"-->
        <!--            >-->
        <!--              <template slot="caret">-->
        <!--                <div class="multiselect__select select&#45;&#45;search">-->
        <!--                  <i class="ion-ios-search"></i>-->
        <!--                </div>-->
        <!--              </template>-->
        <!--            </multiselect>-->
        <!--            <span class="val-error mt-2" v-if="validation.hasError('selectedBank')"-->
        <!--              >{{ validation.firstError('selectedBank') }}-->
        <!--            </span>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="date--time mt-2 p-3">
          {{ $t('booking.modal.digitalAgreement.bankDesc') }}
        </div>
        <div class="form--group mt-2" :class="{ 'has-error': validation.hasError('bankName') }">
          <div class="left--col">
            <label for="bankName">
              <span>{{ $t('bankForm.bankName') }}</span>
              <span class="required">*</span>
            </label>
          </div>
          <div class="right--col">
            <input id="bankName" v-model="bankName" class="basic--input" />
            <span class="val-error mt-2" v-if="validation.hasError('bankName')"
              >{{ validation.firstError('bankName') }}
            </span>
          </div>
        </div>
        <div
          class="form--group mt-2"
          :class="{ 'has-error': validation.hasError('bankAccountName') }"
        >
          <div class="left--col">
            <label for="bankAccountNumber">
              <span>{{ $t('bankForm.bankAccountName') }}</span>
              <span class="required">*</span>
            </label>
          </div>
          <div class="right--col">
            <input id="bankAccountName" v-model="bankAccountName" class="basic--input" />
            <span class="val-error mt-2" v-if="validation.hasError('bankAccountName')"
              >{{ validation.firstError('bankAccountName') }}
            </span>
          </div>
        </div>
        <div
          class="form--group mt-2"
          :class="{ 'has-error': validation.hasError('bankAccountNumber') }"
        >
          <div class="left--col">
            <label for="bankAccountNumber">
              <span>{{ $t('bankForm.accountNumber') }}</span>
              <span class="required">*</span>
            </label>
          </div>
          <div class="right--col">
            <input
              id="bankAccountNumber"
              @keypress="isNumber"
              v-model="bankAccountNumber"
              class="basic--input"
            />
            <span class="val-error mt-2" v-if="validation.hasError('bankAccountNumber')"
              >{{ validation.firstError('bankAccountNumber') }}
            </span>
          </div>
        </div>
        <div class="form--group mt-2" :class="{ 'has-error': validation.hasError('agentName') }">
          <div class="left--col">
            <label for="agentName">
              <span>{{ $t('booking.modal.digitalAgreement.agentName') }}</span>
              <!--              <span class="required">*</span>-->
            </label>
          </div>
          <div class="right--col">
            <input id="agentName" v-model="agentName" class="basic--input" />
            <span class="val-error mt-2" v-if="validation.hasError('agentName')"
              >{{ validation.firstError('agentName') }}
            </span>
          </div>
        </div>
        <div
          class="form--group mt-2"
          :class="{ 'has-error': validation.hasError('agentCeaNumber') }"
        >
          <div class="left--col">
            <label for="agentCeaNumber">
              <span>{{ $t('booking.modal.digitalAgreement.agentCeaNumber') }}</span>
              <!--              <span class="required">*</span>-->
            </label>
          </div>
          <div class="right--col">
            <input
              id="agentCeaNumber"
              @keypress="isNumber"
              v-model="agentCeaNumber"
              class="basic--input"
            />
            <span class="val-error mt-2" v-if="validation.hasError('agentCeaNumber')"
              >{{ validation.firstError('agentCeaNumber') }}
            </span>
          </div>
        </div>
        <div class="disclaimer mt-2">
          {{ $t('booking.modal.digitalAgreement.disclaimer') }}
        </div>

        <div class="d-flex justify-content-center mt-4">
          <div class="btn btn-primary--outline main--btn mt-0" @click="previewDefaultAgreement">
            <img src="@/assets/img/icons/document.svg" class="mr-2" />
            <span>{{ $t('general.btn.previewAgreement') }}</span>
          </div>
        </div>
        <hr class="separator" />
        <div class="d-flex justify-content-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-primary--outline main--btn" @click="back">
            {{ $t('general.btn.back') }}
          </div>
          <div v-if="modalData" class="btn btn-primary main--btn" @click="submit">
            {{ $t('booking.modal.acceptBook.acceptBtn') }}
          </div>
        </div>
      </section>
      <section class="modal--container accept--survey-container" v-else>
        <div class="d-flex justify-content-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-primary--outline main--btn mt-0 mb-4" @click="back">
            {{ $t('general.btn.back') }}
          </div>
        </div>
        <div class="title--text">{{ $t('booking.digitalAgreementPreview') }}</div>
        <hr />
        <div v-html="digitalSignPreview" class="preview--wrapper"></div>
        <hr class="separator" />
        <div class="d-flex justify-content-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-primary--outline main--btn" @click="back">
            {{ $t('general.btn.back') }}
          </div>
        </div>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import ModalMixin from '../mixins/index';
import HelperMixin from '@/mixins/helpers';
import { Validator } from 'simple-vue-validator';
// import Multiselect from 'vue-multiselect';
// require('vue-multiselect/dist/vue-multiselect.min.css');
import { mapGetters, mapState } from 'vuex';
const SectionLoader = () => import('@/components/content-loading/section-loading');

export default {
  name: 'rent-digital-agreement-modal',
  mixins: [ModalMixin, HelperMixin],
  components: {
    SectionLoader,
    // Multiselect,
  },
  data() {
    return {
      modalName: 'rent-digital-agreement-modal',
      modalData: null,
      startDate: '',
      endDate: '',
      actionType: null,
      digitalSignPreview: '',
    };
  },
  computed: {
    ...mapGetters({
      formData: 'v2/rentTransaction/acceptRequest/formData',
    }),
    ...mapState({
      bankList: state => state.v2.bankForm.bankList,
    }),
    isLoading: {
      get() {
        return this.$store.state.v2.rentTransaction.acceptRequest.isLoading;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/acceptRequest/SET_IS_LOADING', val);
      },
    },
    ownerName: {
      get() {
        return this.$store.state.v2.rentTransaction.acceptRequest.ownerName;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/acceptRequest/SET_OWNER_NAME', val);
      },
    },
    ownerKtp: {
      get() {
        return this.$store.state.v2.rentTransaction.acceptRequest.ownerKtp;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/acceptRequest/SET_OWNER_KTP', val);
      },
    },
    ownerEmail: {
      get() {
        return this.$store.state.v2.rentTransaction.acceptRequest.ownerEmail;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/acceptRequest/SET_OWNER_EMAIL', val);
      },
    },
    selectedBank: {
      get() {
        return this.$store.state.v2.rentTransaction.acceptRequest.selectedBank;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/acceptRequest/SET_SELECTED_BANK', val);
      },
    },
    bankName: {
      get() {
        return this.$store.state.v2.rentTransaction.acceptRequest.bankName;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/acceptRequest/SET_BANK_NAME', val);
      },
    },
    bankAccountNumber: {
      get() {
        return this.$store.state.v2.rentTransaction.acceptRequest.bankAccountNumber;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/acceptRequest/SET_BANK_ACCOUNT_NUMBER', val);
      },
    },
    bankAccountName: {
      get() {
        return this.$store.state.v2.rentTransaction.acceptRequest.bankAccountName;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/acceptRequest/SET_BANK_ACCOUNT_NAME', val);
      },
    },
    agentName: {
      get() {
        return this.$store.state.v2.rentTransaction.acceptRequest.agentName;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/acceptRequest/SET_AGENT_NAME', val);
      },
    },
    agentCeaNumber: {
      get() {
        return this.$store.state.v2.rentTransaction.acceptRequest.agentCeaNumber;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/acceptRequest/SET_AGENT_CEA_NUMBER', val);
      },
    },
  },
  validators: {
    ownerName(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.name.required'))
        .maxLength(50, this.$i18n.t('errors.name.max', { maxChar: 50 }));
    },
    ownerKtp(value) {
      return Validator.value(value).required(this.$i18n.t('errors.identity_card_number.required'));
      // .length(16, this.$i18n.t('errors.identity_card_number.length', { char: 16 }));
    },
    ownerEmail(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.email.required'))
        .email(this.$i18n.t('errors.loginRegister.email.invalid'))
        .maxLength(255, this.$i18n.t('errors.loginRegister.email.max', { maxChar: 255 }));
    },
    bankName(value) {
      return Validator.value(value).required(this.$i18n.t('errors.bankName.required'));
    },
    bankAccountNumber(value) {
      return Validator.value(value).required(this.$i18n.t('errors.accountNumber.required'));
    },
    bankAccountName(value) {
      return Validator.value(value).required(this.$i18n.t('errors.accountName.required'));
    },
    agentName(value) {
      if (this.agentCeaNumber) {
        return Validator.value(value).required(this.$i18n.t('errors.agentName.required'));
      } else {
        return Validator.value(true);
      }
    },
    agentCeaNumber(value) {
      if (this.agentName) {
        return Validator.value(value).required(this.$i18n.t('errors.agentCeaNumber.required'));
      } else {
        return Validator.value(true);
      }
    },
  },
  methods: {
    async beforeOpen(event) {
      this._closed();
      this._applicationUuid = event.params.uuid;
      this.actionType = event.params.actionType;
      await this._fetchApplicationData();
      await this.$store.dispatch('v2/bankForm/getFormData');
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validate() {
      let validationArray = [];
      validationArray.push(this.$validate());
      return Promise.all(validationArray).then(function(results) {
        return (
          results.filter(function(result) {
            return !result;
          }).length === 0
        );
      });
    },
    back() {
      if (!this.digitalSignPreview) {
        this.$modal.show('rent-accept-modal', {
          uuid: this._applicationUuid,
        });
        this.close();
      } else {
        this.digitalSignPreview = null;
      }
    },
    async submit() {
      if (await this.validate()) {
        try {
          this.isLoading = true;
          const result = await this.$store.dispatch(
            'v2/rentTransaction/acceptRequest/postAcceptBooking',
          );
          if (result) {
            this.$emit('actionDone');
            this._close();
            this.$swal(
              this.$t('general.success'),
              this.$t('booking.swal.bookingModal.acceptedMsg'),
              'success',
            );
          }
          // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
          this.isLoading = false;
        }
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
    async previewDefaultAgreement() {
      if (await this.validate()) {
        try {
          this.isLoading = true;
          let formData = this.formData;
          formData.rent_transaction_uuid = this._applicationUuid;
          const result = await this.$store.dispatch(
            'v2/booking/acceptBookingRequest/previewDigitalAgreement',
            formData,
          );
          if (result && result.template) {
            let elements = this.$el.getElementsByClassName('v--modal');
            if (elements && elements.length > 0) {
              for (let el of elements) {
                el.scrollTop = 0;
              }
            }
            this.digitalSignPreview = result.template;
          }
          // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
          this.isLoading = false;
        }
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('booking.modal.rejectNego.title') }}
      </div>
      <SectionLoader :show="loading" />
      <section class="modal--container accept--survey-container">
        <div class="title--text" v-if="$mq !== 'xs'">
          {{ $t('booking.modal.rejectNego.title') }}
        </div>
        <div class="date--time">
          {{ $t('booking.modal.rejectNego.message') }}
        </div>
        <hr class="separator" />
        <div class="d-flex justify-content-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-primary--outline main--btn" @click="close">
            {{ $t('general.btn.back') }}
          </div>
          <div class="btn btn-danger main--btn" @click="reject">
            {{ buttonName }}
          </div>
        </div>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import ModalMixin from '../mixins/index';
import HelperMixin from '@/mixins/helpers';
const SectionLoader = () => import('@/components/content-loading/section-loading');

export default {
  name: 'reject-nego-modal',
  mixins: [ModalMixin, HelperMixin],
  components: { SectionLoader },
  data() {
    return {
      modalName: 'reject-nego-modal',
      buttonName: '',
      actionType: null,
    };
  },
  computed: {
    loading: {
      get() {
        return this.$store.state.nego.loading;
      },
      set(val) {
        this.$store.commit('nego/SET_LOADING', val);
      },
    },
    uuid: {
      get() {
        return this.$store.state.nego.uuid;
      },
      set(val) {
        this.$store.commit('nego/SET_UUID', val);
      },
    },
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    async beforeOpen(event) {
      this._applicationUuid = event.params.uuid;
      this.actionType = event.params.actionType;
      this.buttonName = event.params.buttonName;
      await this._fetchApplicationData();
      if (this.modalData) {
        this.uuid = this.modalData.nego_uuid;
      }
    },
    async reject() {
      try {
        let data = await this.$store.dispatch('nego/cancel');
        if (data) {
          this.$emit('actionDone');
          this.close();
          this.$swal(
            this.$t('general.success'),
            this.$t('booking.swal.cancelNego.message'),
            'success',
          );
        }
        // eslint-disable-next-line no-empty
      } catch (_) {}
    },
  },
};
</script>

<style scoped></style>
